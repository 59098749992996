import React from 'react';

import '../components/style.scss';
import Helmet from '../components/helmet';
import Header from '../components/header';
import Midsection from '../components/midsection';
import Footer from '../components/footer';
// Transition stuff
import PropTypes from "prop-types"
import Transition from '../components/transition'
import { ContextProviderComponent } from "../components/context"
import ContextConsumer from "../components/context"

//const Layout = props => {
const Layout = ({ children, location }) => (
  //const {
  //  header_image,
  //  header_text_colour,
  //  body_colour,
  //  text_colour,
  //  children,
  //  location
  //} = props

  //  const header_image = false;
  //  const header_text_colour = "#000";
  //  const body_colour = "#FFF";
  //  const text_colour = "#000";

  // Wrapper style
  //const wrapper_style = {
  //  background: body_colour,
  //  color: text_colour,
  //}

  // Background image style
  //if (header_image){
  //  const header_bg_style = {
  //  	backgroundImage: 'url(' + header_image.childImageSharp.fluid.src + ')',
  //  	backgroundRepeat: 'no-repeat',
  //  	backgroundSize: 'contain',
  //  	
  //  }
  //  //background: header_image.childImageSharp.fluid.src,
  //} else {
  //  const header_bg_style = {};
  //}
  //return (
  //  <Transition location = {location}>
  //  <div id="siteWrapper" style={{...wrapper_style, ...header_bg_style}}>
  //  	<Helmet colour={body_colour} />
  //  	<Header
  //          //svg_logo={props.svg_logo}
  //          text_colour={header_text_colour}
  //      />
  //  	<Midsection>
  //  		{ children }
  //  	</Midsection>
  //  	<Footer />
  //  </div>
  //  </Transition>
  // )
		<ContextProviderComponent>
		  <ContextConsumer>
			{cdata => 
				<div
				  id="siteWrapper"
          	  	  key={location.pathname}
				  style={{
				    backgroundColor: cdata.theme.body_colour,
					color: cdata.theme.text_colour,
					// TODO - add header image
					//backgroundImage: cdata.theme.header_image ? ('url(' + cdata.theme.header_image.childImageSharp.fluid.src + ')') : 'none',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
				  }}
				>
				<div className="header-background"
				  style={{
					backgroundImage: cdata.theme.header_image ? ('url(' + cdata.theme.header_image.childImageSharp.fluid.src + ')') : 'none',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: 0,
				  }}
				>
				</div>
				<Helmet colour={cdata.theme.body_colour} />
				<Header
					//svg_logo={props.svg_logo}
					text_colour={cdata.theme.header_text_colour}
				/>
				<Midsection>
					{/**<h1>{cdata.theme.body_colour}</h1>**/}
					{/**<Transition location = {location}>**/}
					{ children }
					{/**</Transition>**/}
				</Midsection>
				<Footer />
				</div>
			}
		  </ContextConsumer>
		</ContextProviderComponent>
)

export default Layout;
